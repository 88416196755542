<template>
  <!--xs: phone, sm: pad, md: pc, lg: 2k-pc, xl: 4k-pc-->
  <nav id="header">
    <!-- 顶部导航 -->
    <div class="ccontainer">
      <ul class="ccontainer-left-ul">
        <li>
          <img id="icon" src="../assets/img/favicon.svg" />
        </li>
        <li :class="(activeNameMock == item.activeName) || 0 ? 'menu-item-active' : 'ccontainer-left-li'"
          @click="toActiveMenuItem(item)" v-for="(item, index) in leftMenuList" :key="index">
          {{ $t(item.titleName) }}
        </li>
      </ul>

      <ul class="ccontainer-right-ul">

        <!--  <li :class="$store.state.activeName === item.activeName ? 'menu-item-active' -->
        <li :class="activeNameMock == item.activeName ? 'menu-item-active' : 'ccontainer-right-li'"
          @click="toActiveMenuItem(item)" v-for="(item, index) in rightMenuList" :key="index">
          {{ item.titleName }}
        </li>
        <li id="bars" @click="dropDownShow = !dropDownShow">
          ...
        </li>
      </ul>
    </div>

    <!-- 下拉菜单 -->
    <transition name="dropdown-fade-show">
      <div v-show="dropDownShow" class="dropdown">
        <ul class="dropdown-top-ul">
          <li class="dropdown-top-li" v-for="(item, index) in leftMenuList" :key="index" @click="toActiveMenuItem(item)">
            {{ item.titleName }}</li>
        </ul>
        <ul class="dropdown-bottom-ul">
          <li class="dropdown-bottom-li" v-for="(item, index) in rightMenuList" :key="index"
            @click="toActiveMenuItem(item)">{{ item.titleName }}</li>
        </ul>
      </div>
    </transition>

  </nav>
</template>
<script>


export default {
  name: 'Header',
  mounted() {
    // this.$store.state.activeName="Home"
    // this.activeNameMock = "Supportq"
  },
  data() {
    return {
      dropDownShow: false, // 控制下拉菜单显示
      leftMenuList: [ // 左侧菜单内容
        { activeName: 'Home', titleName: 'Home', activeUrl: '/index' },
        { activeName: 'Blog', titleName: 'Blog', activeUrl: '/blog' },
      ],
      rightMenuList: [ // 右侧菜单内容
        { activeName: 'About', titleName: 'About', activeUrl: '/about' }

      ],
      activeName: '',// 导航栏激活名称,
      activeNameMock: '',
    }
  },
  methods: {
    toActiveMenuItem(item) { // 激活导航菜单
      this.activeName = item.titleName
      this.$router.push(item.activeUrl)
      this.dropDownShow = false
    }
  }
}

</script>

<style lang="less" scoped>
/* Less 变量文件 */

// 配置页头的相关参数
@header-height: 60px;
@header-li-width: 70px;

// 导航栏菜单激活颜色
@menu-active-color: #ffbc32;

// 页面样式配置
@headerBg: #19191c;
@headerTextColor: #ffffff;

/* Google 颜色变量 */
@googleRed: #f4433c;
@googleBlue: #2d85f0;
@googleGreen: #0aa858;
@googleYellow: #ffbc32; // 使用已定义的 @menu-active-color


#header {


  ul,li{
   padding: 0; 
   margin: 0; 
   text-align: center;
   font-weight: bold;
  }
  
  color: @headerTextColor;
  background: @headerBg;
  width: 100%;
  position: relative;
  z-index: 108000;
  box-sizing: border-box;

  .ccontainer {
    margin: 0 auto;
    padding: 0 22px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: @header-height; //把高度给这里，副元素默认撑开。当可折叠的关于战开始，父元素也会撑开
    box-sizing: border-box;



    .ccontainer-left-ul {
      li {
        height: 100%;
        line-height: @header-height;
        width: @header-li-width;
        display: inline-block;
      }

      #icon {
        height: 30px;
        vertical-align: middle;
        transition: transform 0.5s;

        &:hover {
          transform: scale(1.5, 1.5) rotate(180deg);
        }
      }

      .ccontainer-left-li:hover {
        color: @menu-active-color;
        box-shadow: 0px -4px 0px @menu-active-color inset;
      }
    }

    .ccontainer-right-ul {

      li {
        height: 100%;
        line-height: @header-height;
        width: @header-li-width;
        display: inline-block;
      }

      .ccontainer-right-li:hover {
        color: @menu-active-color;
        box-shadow: 0px -4px 0px @menu-active-color inset;
      }

      #bars>i {
        padding: 8px 14px;
        border: 1px @headerTextColor solid;
        border-radius: 5px;
      }
    }

  }

  .dropdown {
    //被折叠的部分

    width: 100%;
    background: @headerBg;


    li {
      height: 40px;
      line-height: 40px;

      &:hover {
        background: black;
      }
    }
  }

  &.menu-item-active {
    color: @menu-active-color;
    box-shadow: 0px -4px 0px @menu-active-color inset;
  }


  @media screen and (min-width: 1080px) {

    #bars {
      display: none;
    }

    .ccontainer {
      max-width: 1080px;
    }

  }

  @media screen and (min-width:720px) and (max-width: 1080px) {

    .ccontainer-left-li,
    .ccontainer-right-li {
      display: none !important;
    }

    .ccontainer {
      width: 100%;
      padding: 0 8%; //这个padding 来源于辅助居中样式
    }
  }

  @media screen and (max-width: 720px) {

    .ccontainer-left-li,
    .ccontainer-right-li {
      display: none !important;
    }
  }

}</style>
