<template>
    <div style="width: 100%;">
        <div id="content-wrapper">

        <div v-if="0" id="arc-head">
            <img id="cover" :src="article.urlImgcol">
        </div>

        <div id="mdtext" v-html="render(markdownText)">

        </div>


        <div id="timestamp">

            <span class="sfafa">Create: </span>
                <span>{{ releaseTime }}</span>
                <br>
            <span class="sfafa">Update: </span>
                <span>{{ updatedTime }}</span>

        </div>
        
    </div>

    </div>
</template>


<script>


import markdownit from 'markdown-it'

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
});

export default {
    data() {
        return {
            markdownText: '',
            article:null,
        }
    },

    props: ['id'],
    computed:{
        releaseTime(){
            try{
                return new Date(this.article.createTime);
            }catch{
                return null
            }


        },
        updatedTime(){

            try{
                return new Date(this.article.updateTime);
            }catch{
                return null
            }

        }
    },

    mounted() {


        this.$axios.get(`/api/article/get/${this.id}`)
            .then(res => {
                console.log(res)
                this.markdownText = res.data.content
                this.article=res.data
            })
            .catch(err => {
                console.log(err)
            })



    },
    methods: {

        render(mdText) {
            return md.render(mdText)
        },
    }
}
</script>


<style lang="less">

@totalWidth:90%;


#content-wrapper{
    width: @totalWidth;
    margin: 0 auto;

}

#arc-head{
    width: @totalWidth;
    margin: 0 auto;
    
    >#cover{
        width: 100%;
    }
    
}


#mdtext{
    // overflow: hidden;
    border: 1px rgba(0, 0, 0, 0.233) solid;

    & a{
        word-wrap: break-word;
    }
    & .md-img{
        display: block;
        margin: 0 auto;
    }
}


#timestamp{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.616);
}

</style>
