<template>
    <div id="body" ref="body">
        <div class="flex_auxi " id="MainBannerWrapper" ref="MainBannerWrapper">


            <!-- 
                1. 我们规定，不在flex_auxi中编写特殊的样式

             -->
            <!-- 
            Todo
             1. 导航栏会东
             2. 导航栏一直展示着
        -->

            <div id="main_banner" style="position: relative;">
                <img src="../assets/img/head.png" alt="">
                <p  id="p1" ref="p1"> Around the world </p>
                <p id="p2" ref="p2"> Into the stars </p>
                <a id="more" href="https://insomnia.icu/#/"> More </a>
                <div 
                @click="deleteThisGradient"
                
                style="
                height: 30px;
                width: 30px;
                position: absolute;
                right: 0;
                top: 0;
                ">

                </div>
            </div>
        </div>

        <!-- <p>{{ $t('welcome') }}</p>
        <button @click="$i18n.locale = 'zh';">66</button> -->

 


        <div class="flex_auxi">
            <div class="Subheading">
                Footprint
            </div>
        </div>
        <!-- Hometown -->
        <div class="flex_auxi" id="HometownWrapper">
            <div class="swiper HomeSwiper" id="Hometown">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="Hometown in HometownContent">
                        <img :src="Hometown.imgUrl" alt="1">
                        <div v-if="Hometown.annotation" class="hometown-annotation">
                            {{ Hometown.annotation }}
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
            </div>
        </div>

        <div class="flex_auxi">
            <div class="Subheading">
                Hobby
            </div>
        </div>


        <div class="flex_auxi">
        <div id="Hobby">
            <div class="wrap-wrapper">
                <a href="javascript:;" @click="invokeModal(1)" class="Hobby_item">
                        <div class="hobby_text">Coding</div>
                </a>
                <a href="javascript:;" @click="invokeModal(2)" class="Hobby_item">
                        <div class="hobby_text">Running</div>
                </a>
            </div>
            <div class="wrap-wrapper">
                <a href="javascript:;" @click="invokeModal(3)" class="Hobby_item">
                        <div class="hobby_text">Cubing</div>
                </a>
                <a href="javascript:;" @click="invokeModal(4)" class="Hobby_item">
                        <div class="hobby_text">Sleeping</div>
                </a>
            </div>

        </div>
        </div>

        <div class="flex_auxi">
            <div class="Subheading">
                Coding zeal
            </div>
        </div>



        <!-- Features -->
        <div v-show="!isMobile()">
            <div class="flex_auxi" id="FeaturesWrapper">
                <div class="Features" ref="Features">
                    <div class="Feature_banner_imgs">
                        <!-- Todo -->
                        <div class="Feature_img" v-for="Feature in FeaturesContent">
                            <img :src="Feature.imgUrl" alt="">
                        </div>
                    </div>
                    <!-- Todo -->
                    <div id="Feature_banner_texts" ref="Feature_banner_texts">
                        <a href="javascript:;" class="Feature_text_a" v-for="Feature in FeaturesContent">
                            {{ Feature.boldText }} <br>
                            <div class="Feature_detail">
                                {{ Feature.mainText }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="isMobile()">
            <div class="flex_auxi" id="FeaturesWrapper">

                <div class="Features">
                    <template v-for="Feature in FeaturesContent">
                        <div href="javascript:;" class="Feature_text_a">
                            {{ Feature.boldText }} <br>
                            <div class="Feature_detail">
                                {{ Feature.mainText }}
                            </div>
                        </div>
                        <div class="Feature_img">
                            <img :src="Feature.imgUrl" alt="">
                        </div>

                    </template>
                </div>
            </div>
        </div>

        <div class="flex_auxi">
            <div class="Subheading">
                Proj.
            </div>
        </div>

        <!-- Other saying -->
        <div class="flex_auxi" id="ForwardWrapper" v-if="1">
            <div class="swiper FowardSwiper" id="Forward_direction">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="Forward_item in FowardSwiperContent">
                        <div class="Forward_item">
                            <div class="Forward_item_img">
                                <img :src="Forward_item.imgUrl" alt="">
                            </div>
                            <div class="Forward_item_text">
                                <p class="main_text">{{ Forward_item.mainText }}</p>
                                <a :href="Forward_item.url" target="_blank">{{ Forward_item.name }}</a>
                                <p class="title">{{ Forward_item.title }}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <div class="flex_auxi" id="Forward_controlWrapper">
            <div id="Forward_control_bar" v-if="1">
                <a href="javascript:;">
                    More
                </a>
                <div class="controler">
                    <a href="javascript:;" @click="fowardSwiperPre">
                        <i id="controler_l" ref="controler_l" class="fa-solid fa-arrow-left"></i>
                    </a>
                    <span id="say_information" ref="currentForIndex"></span>
                    /
                    <span ref="ForLength"></span>

                    <a href="javascript:;" @click="fowardSwiperNext">
                        <i id="controler_r" ref="controler_r" class="fa-solid fa-arrow-right"></i>
                    </a>

                </div>
            </div>
        </div>
        <div class="flex_auxi">
            <div class="Subheading">
                Find me
            </div>
        </div>
        <div id="Follow" ref="Follow">
            <ul>
                <li>
                    <a target="_blank" href="https://www.instagram.com/springsecurity" class="Follow_a">
                        <i class="fa-brands fa-instagram" style="color: white;"></i>
                        <span class="Follow_span">Zeon on Ins</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://github.com/NoonieBao" class="Follow_a">
                        <i class="fa-brands fa-github" style="color: white;"></i>
                        <span class="Follow_span">Zeon on Github</span>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="mailto:go@insomnia.icu" class="Follow_a">
                        <i class="fa-regular fa-envelope" style="color: white;"></i>
                        <span class="Follow_span">Email Zeon</span>
                    </a>
                </li>
                
            </ul>
        </div>


  <b-modal ref="modal"  hide-footer  hide-header >
    <p class="my-4">TO BE CONTINUED</p>
  </b-modal>

    </div>
</template>

<script>
import Swiper from 'swiper/bundle';
import _ from 'lodash';
import { getRandomBackground } from '../components/gd'
export default {
    data() {
        return {
            gra:{},
            windowWidth: 0,
            HometownContent: [
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xedX3.jpeg",
                    annotation: "",
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xe54e.jpeg",
                    annotation: "",
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xe809.jpeg",
                    annotation: "",
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xeD1O.jpeg",
                    annotation: "",
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xeEvH.jpeg",
                    annotation: "",
                }
            ],

            FeaturesContent: [
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xepND.jpeg",
                    boldText: "Elegance",
                    mainText: "Elegant code is simple, composable, modular, and gives some new insight. These qualities, are actually deeply related, different facets of the same underlying idea.",
                    url: "https://www.quora.com/What-do-programmers-mean-by-elegant-code"

                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xefOA.jpeg",
                    boldText: "Open Mind",
                    mainText: "It's a waste of energy to prop up an opinion about software like a tenet of religion. And certain technologies can be dismissed pretty flippantly from time to time.",
                    url: "https://spin.atomicobject.com/open-mind-software-development/",
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xe3wo.jpeg",
                    boldText: "Performance",
                    mainText: "Software should be fast. But even though computing devices get faster every year, we should pay attention to the performance of code to ensure it uses resources efficiently.",
                    url: "https://pspdfkit.com/blog/2020/how-to-effectively-measure-performance-code/"
                },

                {
                    imgUrl: "https://i0.imgs.ovh/2024/01/25/xemD5.jpeg",
                    boldText: "Open Source",
                    mainText: 
                    // "You may not have a grand strategy, or just want to share your work to minimize the number of times the wheel needs to be reinvented. "+
                    "It is hard to justify investing resources in growing a community or actively maintaining a project, but this endeavor can still be 'throw it over the wall' so that others are free to use and adapt.",
                    url: "https://opensource.google/documentation/reference/why"
                },

            ],
            FowardSwiperContent: [


                {
                imgUrl: "https://i0.imgs.ovh/2024/02/03/bbRkN.png",
                mainText: "NightGuard, An Xposed module that would halt APPs in your sleeping time."+
                "NightGuard is based on Lsposed, and an alternative enhanced with Magisk.",
                name: "NightGuard",
                url:"https://github.com/NoonieBao/NightGuard",
                title: "maintainer"
                },
                {
                    imgUrl: "https://i0.imgs.ovh/2024/02/03/bbbDU.jpeg",
                    mainText: "A repository to create photo mosaics using python3. "+
                    "Powered by Pillow for image processing and NumPy for matrix manipulation respectively. ",
                    name: "Photo_Mosaic",
                    url:"https://github.com/Datadolittle/Photo_Mosaic",
                    title: "contributor"
                },
            ]

        }
    },
    computed: {

    },
    mounted() {
        this.randomBg()

        this.newFeaJs()

        this.initHomeSwiper()
        this.initForSwiper()
        
        this.initFollow()
        this.initHobby()

        setTimeout(()=>{
            this.animateText();
        },300)
    },
    beforeMount(){

    },
    created() {
        this.windowWidth = window.innerWidth; // 初始化windowWidth的值
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
    deleteThisGradient(){
        this.$axios.get("https://cvdev.top/delete/"+this.gra.id)
    //    console.log("deleted",this.gra.id)
    },
    randomBg(){

        this.$refs.MainBannerWrapper.style.opacity=1
        const q=getRandomBackground()
        this.$refs.MainBannerWrapper.style.backgroundImage=q.gradient
        // console.log(q.gradient)
        this.gra=q
    },

    initHobby()    {

        var hobby_item = document.getElementsByClassName("Hobby_item")
        var div_height = hobby_item[0].offsetHeight

        for (let index = 0; index < hobby_item.length; index++) {
            hobby_item[index].addEventListener("mousemove", function (event) {
                ba_colo_mouse(event, index)
            })

            hobby_item[index].addEventListener("mouseout", function (event) {
                hobby_item[index].style.backgroundImage = "";

            })

        }

        function ba_colo_mouse(event, index) {
            let out_left = hobby_item[index].getBoundingClientRect().left
            let all_x = event.clientX
            let x_position = all_x - out_left

            let out_top = hobby_item[index].getBoundingClientRect().top
            let all_y = event.clientY
            let y_position = all_y - out_top

            hobby_item[index].style.backgroundImage = "radial-gradient( " + div_height + "px " + div_height + "px at "
                + x_position + "px " + y_position + "px,rgba(253, 69, 237,0.8) ,rgba(22, 125, 255,0)";
        }
    },
    invokeModal(index){
        this.$refs.modal.show()
    },

        isMobile() {
            return this.windowWidth < 720;
        },
        handleResize: _.debounce(function () {
            this.windowWidth = window.innerWidth;
        }, 500),
        initFollow() {
            let Follow = this.$refs.Follow
            var Contact_as = Follow.getElementsByClassName("Follow_a")
            var Contact_spans = Follow.getElementsByClassName("Follow_span")
            var Contact_items_num = Contact_as.length
            var Contact_a_hs = []
            var Contact_item_hs = []
            for (let i = 0; i < Contact_items_num; i++) {
                Contact_a_hs[i] = Contact_as[i].offsetWidth
                Contact_item_hs[i] = Contact_as[i].offsetWidth + Contact_spans[i].offsetWidth
            }
            for (let i = 0; i < Contact_items_num; i++) {
                Contact_spans[i].style.width = "0px"
                Contact_as[i].addEventListener("mouseover", function () {

                    if (document.body.clientWidth < 720){   //小于这个就不会展开
                        return
                    }
                    Contact_as[i].style.width = Contact_item_hs[i] + "px"
                })
                Contact_as[i].addEventListener("mouseout", function () {
                    Contact_as[i].style.width = Contact_a_hs[i] + "px"
                })
            }
        },
        fowardSwiperNext() {
            this.fowardSwiper.slideNext();
        },
        fowardSwiperPre() {
            this.fowardSwiper.slidePrev()
        },
        initForSwiper() {

            let vm = this
            this.fowardSwiper = new Swiper(".FowardSwiper", {
                loop: false,
                on: {
                    init: function () {
                        vm.$refs.currentForIndex.innerHTML = 1
                        vm.$refs.ForLength.innerHTML = this.slides.length;
                    },
                    slideChange: function () {
                        vm.$refs.currentForIndex.innerHTML = this.activeIndex + 1
                    }
                }
            });
        },
        initHomeSwiper() {
            this.homeSwiper = new Swiper(".HomeSwiper", {
                loop: false,
                pagination: {
                    el: ".swiper-pagination",
                    dynamicBullets: true,
                },
                autoplay: {
                    delay: 5000, // 设置自动播放的时间间隔，单位为毫秒
                    disableOnInteraction: true, // 用户交互后是否禁止自动播放
                },
            });
        },
        newFeaJs() {
            var fea_index_current, process

            var fps, fps_time, fps_unit,time


            fea_index_current=0
            process=0

            fps=25

            time=5000

            fps_time=1000/25

            fps_unit=1/(fps*(time/1000))  //每一fps的百分率(总fps数的倒数)

            var size

            var timeBus

            var vm = this

            var fea_imgs, fea_a_list, fea_det_list
            
            var the_ul

            var fea_a_h_list, fea_det_h_list

            var minWidthWindows = 800;



            var bg_thread

            var fea_a_width

            
            function getBus(){
                return setInterval(() => {
                //此信号发生器不停歇
                process += fps_unit
                if (process >= 1) {
                    fea_index_current = (fea_index_current + 1) % size
                    process = 0
                    indexChange()
                }
            }, fps_time);
            }

            function init(){
                //一次就好
                fea_imgs = vm.$refs.Features.getElementsByClassName("Feature_img");
                fea_a_list = vm.$refs.Features.getElementsByClassName("Feature_text_a");
                fea_det_list = vm.$refs.Features.getElementsByClassName("Feature_detail");
                the_ul = vm.$refs.Feature_banner_texts;
                size = fea_imgs.length;


            }
            init()
            function init1(){
                fea_a_h_list = []
                fea_det_h_list = []
                fea_a_width = fea_a_list[0].offsetWidth
            }
            function init2(){
                for (let index = 0; index < size; index++) {
                    fea_det_list[index].style.display = "block"
                    fea_det_h_list[index] = fea_det_list[index].offsetHeight//是否可以用overflower hiddeng来获取高度?
                    fea_det_list[index].style.display = "none"
                    fea_a_list[index].style.height = ""
                    fea_a_h_list[index] = fea_a_list[index].offsetHeight
                }
            }
            function getGradienter(){
                if (document.body.clientWidth < minWidthWindows) {
                    return null
                }
                return setInterval(() => {
                    let processPx= (fea_a_width*process)
                    fea_a_list[fea_index_current].style.backgroundImage = "linear-gradient(to right,rgb(212, 212, 213) " + processPx + "px,white " + processPx + "px)"
                    //把上一个设置为none Todo 或者用一个函数还额外的设置
                }, fps_time);
            }
            function reGradients() {
                //可能存在bug,上一个不一定是上一个
                // let index=(fea_index_current+3)%size
                // fea_a_list[index].style.backgroundImage = "";
                for (let index = 0; index < size; index++) {
                    fea_a_list[index].style.backgroundImage = "";
                }
            }
            function detShow(param=fea_index_current) {
                //重新设定被激活的文本。展示其详细内容
                if (document.body.clientWidth < minWidthWindows) {
                    return
                }
                for (let index = 0; index < size; index++) {
                    fea_a_list[index].style.height = fea_a_h_list[index] + "px"
                }
                fea_det_list[param ].style.display = "block"
                fea_a_list[param ].style.height = fea_det_h_list[param ] + fea_a_h_list[param ] + "px"
            }
            function img_shower(param=fea_index_current) {
                //切换图片
                for (let index = 0; index < size; index++) {
                    fea_imgs[index].style.opacity = "0"
                }
                fea_imgs[param].style.opacity = "1"
            }
            function indexChange(){
                //更换图片
                detShow()
                //更换文本
                img_shower()
                //清除渐变
                reGradients()
            }
            function resize(){
                init1()
                init2()
                if (document.body.clientWidth < minWidthWindows) {
                    reGradients() 
                    clearInterval(timeBus)
                    clearInterval(bg_thread)
                    timeBus = null
                    bg_thread = null
                    for (let sfas = 0; sfas < fea_det_h_list.length; sfas++) {
                        fea_det_list[sfas].style.display = "block" //???
                    }
                } else {
                    process = 0
                    if (timeBus == null) {
                        timeBus = getBus()
                    }
                    if (bg_thread == null) {
                        bg_thread = getGradienter()
                    }
                }
            }   

            resize()

            //由于只是display:none, 所以可能不用重新获取元素,也不用重新设置监听
            for (let p = 0; p < size; p++) {
                fea_a_list[p].addEventListener("click", function (event) {
                    fea_index_current = p
                    process=0
                    indexChange()
                })
                fea_a_list[p].addEventListener("mouseover", function () {
                    detShow(p)
                })
            }
            the_ul.addEventListener("mouseover", function () {
                clearInterval(timeBus)
                clearInterval(bg_thread)
            })
            the_ul.addEventListener("mouseout", function () {
                //不需要安端,你移动过去之前,肯定清除了???TODO
                timeBus = getBus()
                bg_thread = getGradienter()
            })
            window.addEventListener('resize', _.debounce(resize, 200));
        },
        feaJs() {

            var vm = this
            var fea_imgs, fea_a_list, fea_det_list, the_ul, size, fea_a_h_list, fea_det_h_list, bg_gd_progress

            // 在某个函数中进行赋值
            function myfunc() {
                fea_imgs = vm.$refs.Features.getElementsByClassName("Feature_img");
                fea_a_list = vm.$refs.Features.getElementsByClassName("Feature_text_a");
                fea_det_list = vm.$refs.Features.getElementsByClassName("Feature_detail");
                the_ul = vm.$refs.Feature_banner_texts;
                size = fea_imgs.length;
                fea_a_h_list = []
                fea_det_h_list = []
            };
            myfunc(vm)
            bg_gd_progress = 0

            var minWidthWindows = 720;

            var fea_fps = 16
            var frame_generate_time = 1000 / fea_fps //1s / fps
            var feature_time_span = 8 * 1000        //毫秒

            var feature_rate
            var fea_a_width


            var fea_index_current = 1

            function get_gradienter() {
                //逐渐设置进度条

                if (document.body.clientWidth < minWidthWindows) {
                    return null
                }
                return setInterval(() => {
                    bg_gd_progress += feature_rate
                    fea_a_list[fea_index_current % size].style.backgroundImage = "linear-gradient(to right,rgb(212, 212, 213) " + bg_gd_progress + "px,white " + bg_gd_progress + "px)"
                    if (bg_gd_progress >= fea_a_width) {
                        fea_a_list[fea_index_current % size].style.backgroundImage = "";
                        bg_gd_progress = 0
                        fea_index_current++
                    }
                }, frame_generate_time);
            }
            function reset_griden() {
                //重置所有进度条,但保持状态
                for (let index = 0; index < size; index++) {
                    fea_a_list[index].style.backgroundImage = "";
                }
            }
            function details_shower(param) {
                //重新设定被激活的文本。展示其详细内容
                if (document.body.clientWidth < minWidthWindows) {
                    return
                }
                for (let index = 0; index < size; index++) {
                    fea_a_list[index].style.height = fea_a_h_list[index] + "px"
                }
                fea_det_list[param % size].style.display = "block"
                fea_a_list[param % size].style.height = fea_det_h_list[param % size] + fea_a_h_list[param % size] + "px"
            }
            function img_shower() {
                //切换图片
                for (let index = 0; index < size; index++) {
                    //Feaimgs 已经被修改了？
                    fea_imgs[index].style.opacity = "0"
                }
                fea_imgs[fea_index_current % size].style.opacity = "1"
            }
            function get_banner_thread() {
                //管理了两个任务。切换图片和自动手风琴（进度条不再此处实现）

                details_shower(fea_index_current)
                return setInterval(function () {
                    img_shower(fea_index_current)
                    details_shower(fea_index_current)
                }, feature_time_span)
            }
            var img_det_thread = get_banner_thread()
            var det_gra_thread = get_gradienter()   //Todo 可以和前两个放在一起么？

            function renew_feature(vm) {

                myfunc(vm)//Todo 看情况

                {
                    fea_a_width = fea_a_list[0].offsetWidth
                    feature_rate = frame_generate_time * fea_a_width / (feature_time_span)
                }

                for (let index = 0; index < size; index++) {
                    fea_det_list[index].style.display = "block"
                    fea_det_h_list[index] = fea_det_list[index].offsetHeight
                    fea_det_list[index].style.display = "none"
                    fea_a_list[index].style.height = ""
                    fea_a_h_list[index] = fea_a_list[index].offsetHeight
                }
                if (document.body.clientWidth < minWidthWindows) {
                    reset_griden()
                    clearInterval(det_gra_thread)
                    clearInterval(img_det_thread)
                    det_gra_thread = null
                    img_det_thread = null
                    for (let sfas = 0; sfas < fea_det_h_list.length; sfas++) {
                        fea_det_list[sfas].style.display = "block"
                    }
                } else {
                    bg_gd_progress = 0
                    if (det_gra_thread == null) {
                        det_gra_thread = get_gradienter()
                    }
                    clearInterval(img_det_thread)
                    img_det_thread = get_banner_thread()

                }
            }
            renew_feature()

            //Todo 需要防抖
            window.addEventListener('resize', _.debounce(renew_feature, 500));

            //下面都是监听
            for (let p = 0; p < size; p++) {
                fea_a_list[p].addEventListener("click", function (event) {
                    reset_griden()
                    img_shower(p)
                    img_det_thread = null
                    fea_index_current = p
                })
                fea_a_list[p].addEventListener("mouseover", function () {
                    //Todo
                    //记录，移除监听
                    details_shower(p)
                })
            }
            the_ul.addEventListener("mouseover", function () {
                clearInterval(img_det_thread)
                clearInterval(det_gra_thread)
            })
            the_ul.addEventListener("mouseout", function () {
                bg_gd_progress = 0
                img_det_thread = get_banner_thread()
                det_gra_thread = get_gradienter()
            }
            )
        },

        animateText() {
            const p1 = this.$refs.p1;
            const p2 = this.$refs.p2;

            if (sessionStorage.getItem('animateTextExecuted')) {

                p1.style.transition = "none"
                p2.style.transition = "none"
                p1.style.transition = "opacity 2500ms"
                p2.style.transition = "opacity 2500ms"
            }
            // 设置样式动画
            p1.style.left = "0";
            p2.style.left = "0";
            p1.style.top = "0";
            p2.style.top = "0";

            p1.style.opacity = 1
            p2.style.opacity = 1

            sessionStorage.setItem('animateTextExecuted', true);
            console.log(sessionStorage.getItem('animateTextExecuted'))
            // 检查 sessionStorage 中的状态


        },

    }
};
</script>

<style lang="less" scoped>
@import 'swiper/swiper-bundle.css';

// @import url("../assets/iconfont/font_4073860_hutb1i23265/iconfont.css");
@import url("//at.alicdn.com/t/c/font_4073860_kbne1jezqbg.css");


@import url("../assets/sty/index.css");
@import url("../assets/sty/reset.css");

.HomeSwiper {

    // 引入的先后顺序可能会导致覆盖
    :global(.swiper-pagination-bullet) {
        background-color: rgb(0, 0, 0);
        opacity: 0.8;
    }

    :global(.swiper-pagination-bullet-active) {
        background-color: white;
        opacity: 1;
    }

    :global(.swiper-pagination) {
        background-color: rgba(255, 255, 255, 0.624);
        padding: 3px 5px;
        border-radius: 6px;
    }
}

.qqqq{

& button.close {
    display:none !important;
}
}


</style>