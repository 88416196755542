var bgList=[{"id":"112","gradient":"radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )"},
{"id":"20","gradient":"linear-gradient(to right, rgb(242, 112, 156), rgb(255, 148, 114))"},
{"id":"3","gradient":"linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))"},
{"id":"379","gradient":"linear-gradient( 109.6deg,  rgba(156,252,248,1) 11.2%, rgba(110,123,251,1) 91.1% )"},
{"id":"376","gradient":"linear-gradient( 109.6deg,  rgba(253,199,141,1) 11.3%, rgba(249,143,253,1) 100.2% )"},
{"id":"116","gradient":"linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%)"},
{"id":"27622","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(14,174,87,1) 0%, rgba(12,116,117,1) 90% )"},
{"id":"17276","gradient":"linear-gradient( 109.6deg,  rgba(48,207,208,1) 11.2%, rgba(51,8,103,1) 92.5% )"},
{"id":"27620","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,209,67,1) 0%, rgba(255,145,83,1) 90% )"},
{"id":"377","gradient":"linear-gradient( 109.6deg,  rgba(218,185,252,1) 11.2%, rgba(125,89,252,1) 91.1% )"},
{"id":"7828","gradient":"linear-gradient( 68.4deg,  rgba(248,182,204,1) 0.5%, rgba(192,198,230,1) 49%, rgba(225,246,240,1) 99.8% )"},
{"id":"378","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,193,104,1) 0%, rgba(251,128,128,1) 90% )"},
{"id":"27542","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,94,247,1) 17.8%, rgba(2,245,255,1) 100.2% )"},
{"id":"22609","gradient":"linear-gradient( 109.6deg,  rgba(62,161,219,1) 11.2%, rgba(93,52,236,1) 100.2% )"},
{"id":"911","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(90,92,106,1) 0%, rgba(32,45,58,1) 81.3% )"},
{"id":"27565","gradient":"linear-gradient( 109.6deg,  rgba(61,245,167,1) 11.2%, rgba(9,111,224,1) 91.1% )"},
{"id":"34150","gradient":"linear-gradient( 174.2deg,  rgba(255,244,228,1) 7.1%, rgba(240,246,238,1) 67.4% )"},
{"id":"34283","gradient":"linear-gradient( 102.4deg,  rgba(253,189,85,1) 7.8%, rgba(249,131,255,1) 100.3% )"},
{"id":"89","gradient":"linear-gradient(to right, #ad5389, #3c1053)"},
{"id":"375","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(128,248,174,1) 0%, rgba(223,244,148,1) 90% )"},
{"id":"17999","gradient":"linear-gradient( 109.6deg,  rgba(5,84,94,1) 16%, #bbb 91.1% )"},
{"id":"17247","gradient":"radial-gradient( circle 321px at 8.3% 75.7%,  rgba(209,247,241,1) 0%, rgba(249,213,213,1) 81% )"},
{"id":"10300","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(222,168,248,1) 0%, rgba(168,222,248,1) 21.8%, rgba(189,250,205,1) 35.6%, rgba(243,250,189,1) 52.9%, rgba(250,227,189,1) 66.8%, rgba(248,172,172,1) 90%, rgba(254,211,252,1) 99.7% )"},
{"id":"17994","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,229,168,1) 0%, rgba(251,174,222,1) 100.7% )"},
{"id":"4","gradient":"linear-gradient(25deg,#d64c7f,#ee4758 50%);\r\n"},
{"id":"34249","gradient":"radial-gradient( circle farthest-corner at 92.3% 71.5%,  rgba(83,138,214,1) 0%, rgba(134,231,214,1) 90% )"},
{"id":"26934","gradient":"linear-gradient( 103.3deg,  rgba(252,225,208,1) 30%, rgba(255,173,214,1) 55.7%, rgba(162,186,245,1) 81.8% )"},
{"id":"225","gradient":"radial-gradient( circle farthest-corner at 1.3% 2.8%,  rgba(239,249,249,1) 0%, rgba(182,199,226,1) 100.2% )"},
{"id":"27470","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% )"},
{"id":"67","gradient":"linear-gradient(to top, #0c3483 0%, #a2b6df 100%, #6b8cce 100%, #a2b6df 100%)"},
{"id":"362","gradient":"linear-gradient( 65.5deg,  rgba(23,205,205,1) -15.1%, rgba(23,25,95,1) 71.5% )"},
{"id":"15778","gradient":"linear-gradient( 112.1deg,  rgba(32,38,57,1) 11.4%, rgba(63,76,119,1) 70.2% )"},
{"id":"27418","gradient":"linear-gradient( 109.6deg,  rgba(247,253,166,1) 11.2%, rgba(128,255,221,1) 57.8%, rgba(255,128,249,1) 85.9% )"},
{"id":"27569","gradient":"linear-gradient( 68.3deg,  rgba(245,177,97,1) 0.4%, rgba(236,54,110,1) 100.2% )"},
{"id":"34305","gradient":"linear-gradient( 68.4deg,  rgba(99,251,215,1) -0.4%, rgba(5,222,250,1) 100.2% )"},
{"id":"27570","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(97,186,255,1) 0%, rgba(166,239,253,1) 90.1% )"},
{"id":"232","gradient":"linear-gradient(to right, #c6ffdd, #fbd786, #f7797d)"},
{"id":"27572","gradient":"radial-gradient( circle farthest-corner at 10.2% 55.8%,  rgba(252,37,103,1) 0%, rgba(250,38,151,1) 46.2%, rgba(186,8,181,1) 90.1% )"},
{"id":"17348","gradient":"linear-gradient( 109.6deg,  rgba(238,58,136,1) 11.2%, rgba(128,162,245,1) 91.1% )"},
{"id":"270","gradient":"linear-gradient( 110.5deg,  rgba(248,196,249,0.66) 22.8%, rgba(253,122,4,0.15) 64.6% )"},
{"id":"22290","gradient":"linear-gradient( 65.9deg,  rgba(85,228,224,1) 5.5%, rgba(75,68,224,0.74) 54.2%, rgba(64,198,238,1) 55.2%, rgba(177,36,224,1) 98.4% )"},
{"id":"45486","gradient":"linear-gradient( 58.2deg,  rgba(40,91,212,0.73) -3%, rgba(171,53,163,0.45) 49.3%, rgba(255,204,112,0.37) 97.7% )"},
{"id":"163","gradient":"linear-gradient( 135deg, #5EFCE8 10%, #736EFE 100%)"},
{"id":"27525","gradient":"linear-gradient( 132.6deg,  rgba(71,139,214,1) 23.3%, rgba(37,216,211,1) 84.7% )"},
{"id":"34146","gradient":"linear-gradient( 64.3deg,  rgba(254,122,152,0.81) 17.7%, rgba(255,206,134,1) 64.7%, rgba(172,253,163,0.64) 112.1% )"},
{"id":"172","gradient":"linear-gradient(to right, #3a1c71, #d76d77, #ffaf7b)"},
{"id":"72","gradient":"radial-gradient( circle farthest-corner at 10% 20%, rgba(255,200,124,1) 0%, rgba(252,251,121,1) 90% )"},
{"id":"12124","gradient":"linear-gradient( 122.3deg,  rgba(111,71,133,1) 14.6%, rgba(232,129,166,1) 29.6%, rgba(237,237,183,1) 42.1%, rgba(244,166,215,1) 56.7%, rgba(154,219,232,1) 68.7%, rgba(238,226,159,1) 84.8% )"},
{"id":"27566","gradient":"radial-gradient( circle 588px at 31.7% 40.2%,  rgba(225,200,239,1) 21.4%, rgba(163,225,233,1) 57.1% )"},
{"id":"13685","gradient":"linear-gradient( 171.8deg,  rgba(5,111,146,1) 13.5%, rgba(6,57,84,1) 78.6% )"},
{"id":"184","gradient":"radial-gradient( circle farthest-corner at -4% -12.9%,  rgba(74,98,110,1) 0.3%, rgba(30,33,48,1) 90.2% )"},
{"id":"17256","gradient":"linear-gradient( 114.2deg,  rgba(121,194,243,1) 22.6%, rgba(255,180,239,1) 67.7% )"},
{"id":"27452","gradient":"linear-gradient( 109.6deg,  rgba(255,179,189,1) 1.8%, rgba(254,248,154,1) 50.6%, rgba(161,224,186,1) 100.3% )"},
{"id":"16223","gradient":"radial-gradient( circle farthest-corner at 0.8% 3.1%,  rgba(255,188,224,1) 0%, rgba(170,165,255,1) 46%, rgba(165,255,205,1) 100.2% )"},
{"id":"27580","gradient":"radial-gradient( circle 610px at 5.2% 51.6%,  rgba(5,8,114,1) 0%, rgba(7,3,53,1) 97.5% )"},
{"id":"22555","gradient":"radial-gradient( circle 597px at 93% 9.8%,  rgba(255,61,89,1) 1.7%, rgba(252,251,44,1) 97% )"},
{"id":"115","gradient":"radial-gradient( circle farthest-corner at 7.5% 24%,  rgba(237,161,193,1) 0%, rgba(250,178,172,1) 25.5%, rgba(190,228,210,1) 62.3%, rgba(215,248,247,1) 93.8% )"},
{"id":"14773","gradient":"linear-gradient( 180.3deg,  rgba(214,224,255,1) 37.2%, rgba(254,168,168,1) 137.3% )"},
{"id":"17302","gradient":"linear-gradient( 110.1deg,  rgba(34,126,34,1) 2.9%, rgba(168,251,60,1) 90.3% )"},
{"id":"16576","gradient":"linear-gradient( 109.5deg,  rgba(76,221,242,1) 11.2%, rgba(92,121,255,1) 91.1% )"},
{"id":"22521","gradient":"linear-gradient( 179.4deg,  rgba(33,150,243,1) 1.8%, rgba(22,255,245,0.60) 97.1% )"},
{"id":"17344","gradient":"linear-gradient( 109.6deg,  rgba(121,203,202,1) 11.2%, rgba(119,161,211,1) 91.1% )"},
{"id":"7446","gradient":"linear-gradient( 90.6deg,  rgba(59,158,255,1) -1.2%, rgba(246,135,255,1) 91.6% )"},
{"id":"27534","gradient":"linear-gradient( 109.6deg,  rgba(209,0,116,1) 11.2%, rgba(110,44,107,1) 91.1% )"},
{"id":"15844","gradient":"radial-gradient( circle farthest-corner at 22.4% 21.7%,  rgba(4,189,228,1) 0%, rgba(2,83,185,1) 100.2% )"},
{"id":"27530","gradient":"linear-gradient( 64.5deg,  rgba(245,116,185,1) 14.7%, rgba(89,97,223,1) 88.7% )"},
{"id":"59","gradient":"linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%)"},
{"id":"16518","gradient":"linear-gradient( 89.9deg,  rgba(178,253,238,0.96) -8.1%, rgba(207,244,254,1) 26.3%, rgba(207,244,254,0.48) 47.5%, rgba(254,219,243,0.63) 61.5%, rgba(254,219,243,1) 78.7%, rgba(254,207,210,1) 109.2% )"},
{"id":"27538","gradient":"radial-gradient( circle 976px at 51.2% 51%,  rgba(11,27,103,1) 0%, rgba(16,66,157,1) 0%, rgba(11,27,103,1) 17.3%, rgba(11,27,103,1) 58.8%, rgba(11,27,103,1) 71.4%, rgba(16,66,157,1) 100.2%, rgba(187,187,187,1) 100.2% )"},
{"id":"2536","gradient":"linear-gradient( 76.5deg,  rgba(129,252,255,1) 0.4%, rgba(255,175,207,1) 49.8%, rgba(255,208,153,1) 98.6% )"},
{"id":"27436","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,152,155,1) 0.1%, rgba(0,94,120,1) 94.2% )"},
{"id":"167","gradient":"linear-gradient( 135deg, #52E5E7 10%, #130CB7 100%)"},
{"id":"22292","gradient":"linear-gradient( 181deg,  rgba(225,109,245,1) 6.9%, rgba(78,248,231,1) 112.8% )"},
{"id":"15385","gradient":"linear-gradient( 111.1deg,  rgba(0,40,70,1) -4.8%, rgba(255,115,115,1) 82.7%, rgba(255,175,123,1) 97.2% )"},
{"id":"625","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,239,132,1) 0%, rgba(247,198,169,1) 54.2%, rgba(21,186,196,1) 100.3% )"},
{"id":"45579","gradient":"radial-gradient( circle 311px at 8.6% 27.9%,  rgba(62,147,252,0.57) 12.9%, rgba(239,183,192,0.44) 91.2% )"},
{"id":"13850","gradient":"linear-gradient( 109.6deg,  rgba(247,202,201,1) 20.6%, rgba(146,168,209,1) 85.9% )"},
{"id":"27308","gradient":"radial-gradient( circle farthest-corner at 7.2% 13.6%,  rgba(37,249,245,1) 0%, rgba(8,70,218,1) 90% )"},
{"id":"27618","gradient":"radial-gradient( circle 860px at 11.8% 33.5%,  rgba(240,30,92,1) 0%, rgba(244,49,74,1) 30.5%, rgba(249,75,37,1) 56.1%, rgba(250,88,19,1) 75.6%, rgba(253,102,2,1) 100.2% )"},
{"id":"233","gradient":"linear-gradient(to right, #12c2e9, #c471ed, #f64f59)"},
{"id":"15338","gradient":"radial-gradient( circle 1224px at 10.6% 8.8%,  rgba(255,255,255,1) 0%, rgba(153,202,251,1) 100.2% )"},
{"id":"5120","gradient":"linear-gradient( 111.5deg,  rgba(228,247,255,1) 21.9%, rgba(255,216,194,1) 92.2% )"},
{"id":"22540","gradient":"linear-gradient( 68.2deg,  rgba(255,202,88,1) 0%, rgba(139,73,255,1) 100.2% )"},
{"id":"34095","gradient":"linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% )"},
{"id":"17248","gradient":"linear-gradient( 100.5deg,  rgba(148,146,236,1) 17.7%, rgba(245,194,194,1) 76.1% )"},
{"id":"17275","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,101,133,1) 0%, rgba(255,211,165,1) 90% )"},
{"id":"27506","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(171,102,255,1) 0%, rgba(116,182,247,1) 90% )"},
{"id":"22423","gradient":"linear-gradient( 109.6deg,  rgba(255,174,0,1) 11.2%, rgba(255,0,0,1) 100.2% )"},
{"id":"16639","gradient":"linear-gradient( 95.2deg,  rgba(173,252,234,1) 26.8%, rgba(192,229,246,1) 64% )"},
{"id":"12354","gradient":"radial-gradient( circle farthest-corner at -14.3% -17.5%,  rgba(245,168,168,1) 4%, rgba(164,168,248,1) 100.2% )"},
{"id":"16322","gradient":"radial-gradient( circle farthest-corner at 1.5% 1.4%,  rgba(159,227,255,1) 0%, rgba(255,177,219,1) 100.2% )"},
{"id":"17998","gradient":"linear-gradient( 111.6deg,  rgba(174,68,223,1) 27.3%, rgba(246,135,135,1) 112.7% )"},
{"id":"11210","gradient":"linear-gradient( 109.6deg,  rgba(204,228,247,1) 11.2%, rgba(237,246,250,1) 100.2% )"},
{"id":"17274","gradient":"linear-gradient( 110.6deg,  rgba(179,157,219,1) 7%, rgba(150,159,222,1) 47.7%, rgba(24,255,255,1) 100.6% )"},
{"id":"22476","gradient":"radial-gradient( circle farthest-corner at 16.5% 28.1%,  rgba(15,27,49,1) 0%, rgba(0,112,218,1) 90% )"},
{"id":"399","gradient":"radial-gradient( circle 592px at 48.2% 50%,  rgba(255,255,249,0.6) 0%, rgba(160,199,254,1) 74.6% )"},
{"id":"15769","gradient":"linear-gradient( 109.6deg,  rgba(44,83,131,1) 18.9%, rgba(95,175,201,1) 91.1% )"},
{"id":"27528","gradient":"linear-gradient( 358.4deg,  rgba(249,151,119,1) -2.1%, rgba(98,58,162,1) 90% )"},
{"id":"22420","gradient":"linear-gradient( 102.1deg,  rgba(96,221,142,1) 8.7%, rgba(24,138,141,1) 88.1% )"},
{"id":"17286","gradient":"radial-gradient( circle farthest-corner at -0.1% 1.8%,  rgba(255,77,77,0.7) 0%, rgba(255,184,129,0.34) 100.2% )"},
{"id":"16529","gradient":"radial-gradient( circle farthest-corner at 11.7% 80.6%,  rgba(249,185,255,1) 0%, rgba(177,172,255,1) 49.3%, rgba(98,203,255,1) 89% )"},
{"id":"14767","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,52,89,1) 0%, rgba(0,168,232,1) 90% )"},
{"id":"22492","gradient":"linear-gradient( 69.7deg,  rgba(244,37,243,1) 1.4%, rgba(244,87,1,1) 36.2%, rgba(255,204,37,1) 72.2%, rgba(20,196,6,1) 113% )"},
{"id":"249","gradient":"linear-gradient( 106.4deg,  rgba(255,104,192,1) 11.1%, rgba(104,84,249,1) 81.3% )"},
{"id":"15417","gradient":"linear-gradient( 65.4deg,  rgba(56,248,249,1) -9.1%, rgba(213,141,240,1) 48%, rgba(249,56,152,1) 111.1% )"},
{"id":"22596","gradient":"radial-gradient( circle 759px at -6.7% 50%,  rgba(80,131,73,1) 0%, rgba(140,209,131,1) 26.2%, rgba(178,231,170,1) 50.6%, rgba(144,213,135,1) 74.1%, rgba(75,118,69,1) 100.3% )"},
{"id":"7010","gradient":"radial-gradient( circle farthest-corner at 6.6% 12%,  rgba(64,0,126,1) 20.8%, rgba(0,255,160,1) 100.2% )"},
{"id":"26809","gradient":"linear-gradient( 92.7deg,  rgba(245,212,212,1) 8.5%, rgba(252,251,224,1) 90.2% )"},
{"id":"13945","gradient":"linear-gradient( 138deg,  rgba(32,201,255,1) 36.7%, rgba(0,8,187,1) 84.4%, rgba(255,255,255,1) 119.7% )"},
{"id":"575","gradient":"linear-gradient( 179.4deg,  rgba(253,240,233,1) 2.2%, rgba(255,194,203,1) 96.2% )"},
{"id":"27380","gradient":"linear-gradient( 99deg,  rgba(115,18,81,1) 10.6%, rgba(28,28,28,1) 118% )"},
{"id":"16644","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(163,175,243,1) 0%, rgba(220,182,232,1) 100.2% )"},
{"id":"5442","gradient":"linear-gradient( 109.6deg,  rgba(14,11,56,1) 11.2%, rgba(239,37,37,1) 91.1% )"},
{"id":"5207","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(215,223,252,1) 0%, rgba(255,255,255,1) 0%, rgba(215,223,252,1) 84% )"},
{"id":"8831","gradient":"linear-gradient( 179.1deg,  rgba(247,238,238,1) -1.9%, rgba(247,202,201,1) 44.9%, rgba(145,168,208,1) 96.1% )"},
{"id":"10122","gradient":"linear-gradient( 89.7deg,  rgba(0,0,0,1) -10.7%, rgba(53,92,125,1) 88.8% )"},
{"id":"11453","gradient":"linear-gradient( 90.1deg,  rgba(255,85,85,1) 0.1%, rgba(85,85,255,1) 100% )"},
{"id":"16325","gradient":"linear-gradient( 135.9deg,  rgba(109,25,252,1) 16.4%, rgba(125,31,165,1) 56.1% )"},
{"id":"5185","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(3,235,255,1) 0%, rgba(152,70,242,1) 100.2% )"},
{"id":"14493","gradient":"linear-gradient( 109.6deg,  rgba(9,9,121,1) 11.2%, rgba(144,6,161,1) 53.7%, rgba(0,212,255,1) 100.2% )"},
{"id":"45551","gradient":"linear-gradient( 111.4deg,  rgba(238,113,113,1) 1%, rgba(246,215,148,1) 58% )"},
{"id":"11196","gradient":"linear-gradient( 109.6deg,  rgba(223,234,247,1) 11.2%, rgba(244,248,252,1) 91.1% )"},
{"id":"12362","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,107,141,1) 0%, rgba(0,69,91,1) 90% )"},
{"id":"17996","gradient":"linear-gradient( 109.6deg,  rgba(255,219,47,1) 11.2%, rgba(244,253,0,1) 100.2% )"},
{"id":"11445","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,95,104,1) 0%, rgba(15,156,168,1) 90% )"},
{"id":"15815","gradient":"linear-gradient( 109.6deg,  rgba(255,24,134,1) 11.2%, rgba(252,232,68,1) 52%, rgba(53,178,239,1) 100.2% )"},
{"id":"22485","gradient":"linear-gradient( 113.3deg,  rgba(217,9,27,1) 6.9%, rgba(22,68,150,1) 75% )"},
{"id":"11230","gradient":"radial-gradient( circle 879px at 10.4% 22.3%,  rgba(255,235,238,1) 0%, rgba(186,190,245,1) 93.6% )"},
{"id":"17139","gradient":"radial-gradient( circle 344px at 1.4% 0%,  rgba(242,185,252,1) 19.3%, rgba(250,254,190,1) 80.7% )"},
{"id":"22264","gradient":"linear-gradient( 94.3deg,  rgba(26,33,64,1) 10.9%, rgba(81,84,115,1) 87.1% )"},
{"id":"14684","gradient":"radial-gradient( circle farthest-corner at 50.4% 50.5%,  rgba(251,32,86,1) 0%, rgba(135,2,35,1) 90% )"},
{"id":"397","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(151,10,130,1) 0%, rgba(33,33,33,1) 100.2% )"},
{"id":"27537","gradient":"linear-gradient( 117deg,  rgba(123,216,96,1) 39.2%, rgba(255,255,255,1) 156.2% )"},
{"id":"16533","gradient":"radial-gradient( circle farthest-corner at 5.3% 17.2%,  rgba(255,208,253,1) 0%, rgba(255,237,216,1) 90% )"},
{"id":"173","gradient":"linear-gradient(to right, #ff416c, #ff4b2b)"},
{"id":"12328","gradient":"linear-gradient( 109.6deg,  rgba(0,0,0,1) 11.2%, rgba(11,132,145,1) 91.1% )"},
{"id":"569","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(69,86,102,1) 0%, rgba(34,34,34,1) 90% )"},
{"id":"14686","gradient":"linear-gradient( 99.6deg,  rgba(112,128,152,1) 10.6%, rgba(242,227,234,1) 32.9%, rgba(234,202,213,1) 52.7%, rgba(220,227,239,1) 72.8%, rgba(185,205,227,1) 81.1%, rgba(154,180,212,1) 102.4% )"},
{"id":"2686","gradient":"linear-gradient( 68.6deg,  rgba(252,165,241,1) 1.8%, rgba(181,255,255,1) 100.5% )"},
{"id":"26786","gradient":"linear-gradient( 109.6deg,  rgba(15,2,2,1) 11.2%, rgba(36,163,190,1) 91.1% )"},
{"id":"22507","gradient":"linear-gradient( 178.2deg,  rgba(118,8,23,1) 10.9%, rgba(158,12,33,1) 62.6% )"},
{"id":"17223","gradient":"linear-gradient( 109.6deg,  rgba(177,173,219,1) 11.2%, rgba(245,226,226,1) 91.1% )"},
{"id":"5964","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(226,240,254,1) 0%, rgba(255,247,228,1) 90% )"},
{"id":"17023","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(62,133,238,1) 1.1%, rgba(227,137,240,1) 43.7%, rgba(243,193,124,1) 89.7% )"},
{"id":"22399","gradient":"linear-gradient( 105.3deg,  rgba(30,39,107,1) 21.8%, rgba(77,118,221,1) 100.2% )"},
{"id":"17308","gradient":"radial-gradient( circle 929px at 0.6% 1.3%,  rgba(248,251,10,1) 0%, rgba(248,47,47,1) 82.6% )"},
{"id":"10162","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,246,236,1) 39.5%, rgba(100,46,122,0.23) 100.2% )"},
{"id":"7424","gradient":"linear-gradient( 111.8deg,  rgba(0,104,155,1) 19.8%, rgba(0,173,239,1) 92.1% )"},
{"id":"169","gradient":"linear-gradient( 135deg, #FFF886 10%, #F072B6 100%)"},
{"id":"100","gradient":"linear-gradient( 134.6deg, rgba(158,150,252,0.85) -1%, rgba(211,133,176,1) 16.9%, rgba(249,116,146,1) 43.7%, rgba(255,158,136,1) 66.9%, rgba(254,228,172,1) 94.8% )"},
{"id":"27524","gradient":"linear-gradient( 129.1deg,  rgba(243,199,83,1) 26.8%, rgba(18,235,207,1) 114.1% )"},
{"id":"14315","gradient":"linear-gradient( 89.9deg,  rgba(208,246,255,1) 0.1%, rgba(255,237,237,1) 47.9%, rgba(255,255,231,1) 100.2% )"},
{"id":"307","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(161,255,206,1) 0%, rgba(250,255,209,1) 90% )"},
{"id":"16206","gradient":"linear-gradient( 89.8deg,  rgba(204,156,223,1) 0.3%, rgba(155,219,245,1) 20.4%, rgba(245,183,208,1) 40.2%, rgba(250,186,98,1) 62.9%, rgba(234,238,240,1) 81.9%, rgba(250,245,171,1) 99.2% )"},
{"id":"4007","gradient":"linear-gradient( 57.4deg,  rgba(249,206,183,1) 4.6%, rgba(234,174,202,1) 26%, rgba(210,182,226,1) 49.1%, rgba(173,209,242,1) 86.5% )"},
{"id":"12997","gradient":"linear-gradient( 110.2deg,  rgba(54,197,240,1) 8.2%, rgba(74,21,75,1) 92.4% )"},
{"id":"1007","gradient":"linear-gradient( 110.8deg,  rgba(86,238,225,1) 11.4%, rgba(176,255,39,1) 84.5% )"},
{"id":"11301","gradient":"radial-gradient( circle 1165px at -5.4% 50.5%,  rgba(0,255,239,1) 4.1%, rgba(243,4,251,1) 47%, rgba(251,223,0,1) 89.5% )"},
{"id":"17354","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(153,103,186,1) 41.2%, rgba(0,113,227,1) 90% )"},
{"id":"45081","gradient":"radial-gradient( circle 369px at -2.9% 12.9%,  rgba(247,234,163,1) 0%, rgba(236,180,238,0.56) 46.4%, rgba(163,203,247,1) 100.7% )"},
{"id":"27505","gradient":"linear-gradient( 134.6deg,  rgba(201,37,107,1) 15.4%, rgba(116,16,124,1) 74.7% )"},
{"id":"27599","gradient":"radial-gradient( circle farthest-corner at 50.7% 54%,  rgba(204,254,152,1) 0%, rgba(229,253,190,1) 92.4% )"},
{"id":"17219","gradient":"linear-gradient( 359deg,  rgba(150,192,255,1) -17.7%, rgba(255,180,161,1) 101.5% )"},
{"id":"45578","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(176,229,208,1) 42%, rgba(92,202,238,0.41) 93.6% )"},
{"id":"10819","gradient":"linear-gradient( 110.3deg,  rgba(238,179,123,1) 8.7%, rgba(216,103,77,1) 47.5%, rgba(114,43,54,1) 89.1% )"},
{"id":"22182","gradient":"linear-gradient( 358.7deg,  rgba(42,136,157,1) 1.7%, rgba(122,197,214,1) 51.1%, rgba(211,232,242,1) 95.5% )"},
{"id":"22234","gradient":"linear-gradient( 359.8deg,  rgba(252,255,222,1) 2.2%, rgba(182,241,171,1) 99.3% )"},
{"id":"6492","gradient":"linear-gradient( 106.7deg,  rgba(151,150,240,1) 12.1%, rgba(255,206,236,1) 63.2% )"},
{"id":"1376","gradient":"linear-gradient( 110.2deg,  rgba(111,71,133,1) 3.6%, rgba(232,129,166,1) 50.3%, rgba(237,237,183,1) 97.4% )"},
{"id":"6565","gradient":"linear-gradient( 111.6deg,  rgba(73,235,221,1) -0%, rgba(83,222,219,1) 7.1%, rgba(105,191,217,1) 13.4%, rgba(127,157,214,1) 18%, rgba(155,113,208,1) 23.9%, rgba(178,73,201,1) 28.8%, rgba(200,45,192,1) 33.3%, rgba(213,42,180,1) 38%, rgba(232,44,145,1) 44.2%, rgba(239,45,128,1) 52.4%, rgba(249,66,107,1) 59.7%, rgba(252,105,98,1) 67.3%, rgba(252,105,98,1) 74.4%, rgba(254,145,92,1) 82.2%, rgba(255,189,86,1) 88.2%, rgba(254,227,80,1) 92.8%, rgba(254,248,75,1) 98.4% )"},
{"id":"9909","gradient":"linear-gradient( 102.7deg,  rgba(253,218,255,1) 8.2%, rgba(223,173,252,1) 19.6%, rgba(173,205,252,1) 36.8%, rgba(173,252,244,1) 73.2%, rgba(202,248,208,1) 90.9% )"},
{"id":"27135","gradient":"radial-gradient( circle farthest-corner at -4.5% 34.3%,  rgba(13,20,174,1) 0%, rgba(243,165,140,1) 90% )"},
{"id":"15768","gradient":"radial-gradient( circle farthest-corner at 14.2% 27.5%,  rgba(104,199,255,1) 0%, rgba(181,126,255,1) 90% )"},
{"id":"423","gradient":"linear-gradient( 112.7deg,  rgba(162,247,241,1) 0.3%, rgba(199,146,255,1) 88.7% )"},
{"id":"183","gradient":"linear-gradient( 63deg,  rgba(242,168,168,1) 2.9%, rgba(94,46,165,1) 102.1% )"},
{"id":"16347","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% )"},
{"id":"22250","gradient":"linear-gradient( 76.3deg,  rgba(44,62,78,1) 12.6%, rgba(69,103,131,1) 82.8% )"},
{"id":"13655","gradient":"radial-gradient( circle farthest-corner at -3.3% 48%,  rgba(141,146,239,1) 0%, rgba(99,229,254,1) 90% )"},
{"id":"10161","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,93,133,1) 0%, rgba(0,181,149,1) 90% )"},
{"id":"15832","gradient":"radial-gradient( circle 939px at 94.7% 50%,  rgba(0,178,169,1) 0%, rgba(0,106,101,1) 76.9% )"},
{"id":"17022","gradient":"radial-gradient( circle 673px at 10% 20%,  rgba(255,220,163,1) 0%, rgba(129,255,239,1) 48.5%, rgba(139,172,251,1) 97.8% )"},
{"id":"22519","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(161,255,255,1) 11.3%, rgba(6,255,255,1) 41.2%, rgba(0,216,216,1) 77.8% )"},
{"id":"15428","gradient":"linear-gradient( 78.1deg,  rgba(177,14,236,1) 5.5%, rgba(255,19,15,1) 92.8% )"},
{"id":"5254","gradient":"linear-gradient( 107deg,  rgba(2,108,223,1) 27.4%, rgba(0,255,255,1) 92.7% )"},
{"id":"27030","gradient":"linear-gradient( 89.4deg,  rgba(194,0,39,1) 0.8%, rgba(10,35,104,1) 99.4% )"},
{"id":"22281","gradient":"linear-gradient( 67.2deg,  rgba(37,208,199,1) -7.5%, rgba(165,90,240,1) 62.7% )"},
{"id":"288","gradient":"radial-gradient( circle farthest-corner at -0.1% 100.8%,  rgba(0,234,255,1) 0.2%, rgba(0,124,255,1) 59.1%, rgba(198,0,255,1) 100.2% )"},
{"id":"63","gradient":"linear-gradient( 111.5deg, rgba(20,100,196,1) 0.4%, rgba(33,152,214,1) 100.2% )"},
{"id":"12352","gradient":"linear-gradient( 181deg,  rgba(2,0,97,1) 15%, rgba(97,149,219,1) 158.5% )"},
{"id":"11718","gradient":"linear-gradient( 90.9deg,  rgba(255,187,187,1) -8.8%, rgba(255,220,177,1) 12.4%, rgba(255,249,177,1) 35.6%, rgba(220,255,177,1) 59.6%, rgba(177,218,255,1) 84%, rgba(222,177,255,1) 107.9% )"},
{"id":"22237","gradient":"linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(155,65,25,0.72) 39.5%, rgba(255,192,0,0.81) 69.7% )"},
{"id":"16611","gradient":"linear-gradient( 112.4deg,  rgba(169,28,115,1) 21.6%, rgba(219,112,54,1) 92.2% )"},
{"id":"238","gradient":"radial-gradient( circle 502px at 2.7% 23.7%,  rgba(142,5,254,1) 0%, rgba(33,250,214,1) 99.6% )"},
{"id":"26874","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(235,131,130,1) 0%, rgba(235,131,130,0.75) 38.6%, rgba(211,177,125,0.52) 72.1%, rgba(211,177,125,0.24) 94.7% )"},
{"id":"26888","gradient":"linear-gradient( 105.5deg,  rgba(31,212,248,1) 11%, rgba(218,15,183,1) 74.9% )"},
{"id":"298","gradient":"linear-gradient( 101.9deg,  rgba(197,120,234,1) 1.6%, rgba(120,220,234,1) 51.2%, rgba(119,249,176,1) 99.8% )"},
{"id":"12350","gradient":"linear-gradient( 109.6deg,  rgba(104,183,249,1) 31.3%, rgba(176,248,200,1) 100.2% )"},
{"id":"12118","gradient":"radial-gradient( circle 263px at 100.2% 3%,  rgba(12,85,141,1) 31.1%, rgba(205,181,93,1) 36.4%, rgba(244,102,90,1) 50.9%, rgba(199,206,187,1) 60.7%, rgba(249,140,69,1) 72.5%, rgba(12,73,116,1) 72.6% )"},
{"id":"15706","gradient":"radial-gradient( circle 590px at 8.2% 13.8%,  rgba(18,35,60,1) 0%, #bbb 90% )"},
{"id":"17294","gradient":"radial-gradient( circle 465px at -15.1% -25%,  rgba(17,130,193,1) 0%, rgba(67,166,238,1) 49%, rgba(126,203,244,1) 90.2% )"},
{"id":"27536","gradient":"linear-gradient( 109.6deg,  rgba(45,116,213,1) 11.2%, rgba(121,137,212,1) 91.2% )"},
{"id":"2537","gradient":"linear-gradient( 75.2deg,  rgba(41,196,255,1) -2.5%, rgba(255,158,211,1) 55%, rgba(255,182,138,1) 102.3% )"},
{"id":"22529","gradient":"linear-gradient( 90.5deg,  rgba(255,207,139,0.50) 1.1%, rgba(255,207,139,1) 81.3% )"},
{"id":"11368","gradient":"linear-gradient( 109.8deg,  rgba(250,111,152,1) 5.6%, rgba(255,189,55,1) 91.5% )"},
{"id":"370","gradient":"radial-gradient( circle 902px at 10% 20%,  rgba(18,50,90,1) 0%, rgba(207,199,252,1) 100.2% )"},
{"id":"17025","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,37,174,1) 0%, rgba(241,147,55,1) 53.4%, rgba(250,237,56,1) 99.5% )"},
{"id":"22252","gradient":"linear-gradient( 293.5deg,  rgba(181,149,208,1) 3.2%, rgba(251,148,207,1) 9.9%, rgba(181,149,208,1) 22.9%, rgba(251,148,207,1) 36.4%, rgba(181,149,208,1) 50.1%, rgba(251,148,207,1) 61.1%, rgba(181,149,208,1) 71.2%, rgba(251,148,207,1) 84.2%, rgba(181,149,208,1) 92.2% )"},
{"id":"22266","gradient":"linear-gradient( 109.6deg,  rgba(116,18,203,1) 11.2%, rgba(230,46,131,1) 91.2% )"},
{"id":"26905","gradient":"radial-gradient( circle 674px at 18.3% 77%,  rgba(139,186,244,1) 3.4%, rgba(15,51,92,1) 56.6% )"},
{"id":"9914","gradient":"radial-gradient( circle farthest-corner at 7.4% 45.1%,  rgba(236,206,251,1) 0%, rgba(205,237,246,1) 45.1%, rgba(227,251,252,1) 78.4%, rgba(230,247,235,1) 90% )"},
{"id":"22473","gradient":"linear-gradient( 112.7deg,  rgba(253,185,83,1) 11.4%, rgba(255,138,0,1) 70.2% )"},
{"id":"45513","gradient":"radial-gradient( circle farthest-corner at 17.1% 22.8%,  rgba(226,24,24,1) 0%, rgba(160,6,6,1) 90% )"},
{"id":"16609","gradient":"linear-gradient( 292.9deg,  rgba(254,105,241,1) 28.8%, rgba(182,44,248,1) 70.4% )"},
{"id":"10789","gradient":"linear-gradient( 103deg,  rgba(137,247,234,1) 7.3%, rgba(102,117,241,1) 100.2% )"},
{"id":"11369","gradient":"linear-gradient( 111.3deg,  rgba(74,105,187,1) 9.6%, rgba(205,77,204,1) 93.6% )"},
{"id":"27262","gradient":"radial-gradient( circle 957px at 8.7% 50.5%,  rgba(246,191,13,1) 0%, rgba(249,47,47,1) 90% )"},
{"id":"9891","gradient":"radial-gradient( circle farthest-corner at -3.1% -4.3%,  rgba(57,255,186,1) 0%, rgba(21,38,82,1) 90% )"},
{"id":"22458","gradient":"linear-gradient( 109.6deg,  rgba(39,142,255,1) 11.2%, rgba(98,113,255,0.78) 100.2% )"},
{"id":"13818","gradient":"radial-gradient( circle 827px at 47.3% 48%,  rgba(255,255,255,1) 0%, rgba(138,192,216,1) 90% )"},
{"id":"14091","gradient":"linear-gradient( 112.9deg,  rgba(112,255,151,1) 6.2%, rgba(70,195,255,1) 99.7% )"},
{"id":"590","gradient":"linear-gradient( 91.1deg,  rgba(57,31,105,1) -2.3%, rgba(115,43,155,1) 44.4%, rgba(231,75,184,1) 103.4% )"},
{"id":"26998","gradient":"linear-gradient( 89.9deg,  rgba(255,243,110,1) 16.9%, rgba(30,204,214,1) 55.1%, rgba(5,54,154,1) 90.7% )"},
{"id":"17039","gradient":"linear-gradient( 99deg,  rgba(255,166,0,1) 14.7%, rgba(255,99,97,1) 73% )"},
{"id":"26849","gradient":"linear-gradient( 109.6deg,  rgba(24,138,141,1) 11.2%, rgba(96,221,142,1) 91.1% )"},
{"id":"27116","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,221,214,1) 0%, rgba(51,102,255,1) 90% )"},
{"id":"5914","gradient":"linear-gradient( 114.9deg,  rgba(34,34,34,1) 8.3%, rgba(0,40,60,1) 41.6%, rgba(0,143,213,1) 93.4% )"},
{"id":"15389","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(7,121,222,1) 0%, rgba(20,72,140,1) 90% )"},
{"id":"7831","gradient":"linear-gradient( 112.4deg,  rgba(176,174,225,1) 44.9%, rgba(135,197,235,1) 94.5% )"},
{"id":"7422","gradient":"linear-gradient( 73.2deg,  rgba(248,205,205,1) 23.2%, rgba(149,170,211,1) 77% )"},
{"id":"26885","gradient":"linear-gradient( 63.1deg,  rgba(5,23,111,1) 16.4%, rgba(24,95,240,1) 64.5% )"},
{"id":"334","gradient":"linear-gradient( 107.2deg,  rgba(18,38,170,1) 12.9%, rgba(0,187,180,1) 77.6%, rgba(255,255,255,1) 125.9% )"},
{"id":"9814","gradient":"linear-gradient( 109.6deg,  rgba(41,125,182,1) 3.6%, rgba(77,58,151,1) 51%, rgba(103,55,115,1) 92.9% )"},
{"id":"15459","gradient":"radial-gradient( circle 266px at 12% 29.3%,  rgba(58,219,154,0.93) 2%, rgba(153,228,208,1) 38.6%, rgba(142,246,219,0.82) 73.9%, rgba(253,255,238,1) 100.7% )"},
{"id":"1214","gradient":"linear-gradient( 109.6deg,  rgba(185,212,242,1) 11.2%, rgba(244,210,226,1) 100.3% )"},
{"id":"4429","gradient":"linear-gradient( 70.6deg,  rgba(172,30,255,1) 0.3%, rgba(65,35,251,1) 55.8%, rgba(35,251,224,1) 105.1% )"},
{"id":"10092","gradient":"radial-gradient( circle farthest-corner at -8.9% 51.2%,  rgba(255,124,0,1) 0%, rgba(255,124,0,1) 15.9%, rgba(255,163,77,1) 15.9%, rgba(255,163,77,1) 24.4%, rgba(19,30,37,1) 24.5%, rgba(19,30,37,1) 66% )"},
{"id":"22128","gradient":"linear-gradient( 110.3deg,  rgba(73,93,109,1) 4.3%, rgba(49,55,82,1) 96.7% )"},
{"id":"7824","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(67,144,233,1) 0%, rgba(78,226,198,1) 90.1% )"},
{"id":"14737","gradient":"linear-gradient( 109.6deg,  rgba(255,207,84,1) 11.2%, rgba(255,158,27,1) 91.1% )"},
{"id":"16564","gradient":"linear-gradient( 89.2deg,  rgba(191,241,236,1) 22.3%, rgba(109,192,236,1) 84.1% )"},
{"id":"6599","gradient":"linear-gradient( 180.3deg,  rgba(221,221,221,1) 5.5%, rgba(110,136,161,1) 90.2% )"},
{"id":"22490","gradient":"linear-gradient( 89.8deg,  rgba(222,74,74,1) 4.7%, rgba(30,29,29,1) 120.3% )"},
{"id":"297","gradient":"linear-gradient( 109.2deg,  rgba(254,3,104,1) 9.3%, rgba(103,3,255,1) 89.5% )"},
{"id":"45398","gradient":"linear-gradient( 179deg,  rgba(0,0,0,1) 9.2%, rgba(127,16,16,1) 103.9% )"},
{"id":"942","gradient":"linear-gradient( 109.6deg,  rgba(255,194,48,1) 11.2%, rgba(255,124,0,1) 100.2% )"},
{"id":"4275","gradient":"radial-gradient( circle 328px at 2.9% 15%,  rgba(191,224,251,1) 0%, rgba(232,233,251,1) 25.8%, rgba(252,239,250,1) 50.8%, rgba(234,251,251,1) 77.6%, rgba(240,251,244,1) 100.7% )"},
{"id":"266","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(255,197,118,1) 0%, rgba(254,106,103,1) 47.7%, rgba(240,23,23,1) 92.3% )"},
{"id":"45104","gradient":"linear-gradient( 109.6deg,  rgba(254,253,205,1) 11.2%, rgba(163,230,255,1) 91.1% )"},
{"id":"13914","gradient":"linear-gradient( 109.6deg,  rgba(123,90,224,1) 11.2%, rgba(164,46,253,1) 32.6%, rgba(213,56,234,1) 62.7%, rgba(251,138,52,1) 100.2% )"},
{"id":"16571","gradient":"radial-gradient( circle 993px at 0.5% 50.5%,  rgba(137,171,245,0.37) 0%, rgba(245,247,252,1) 100.2% )"},
{"id":"10690","gradient":"linear-gradient( 0.2deg,  rgba(51,204,255,1) 4.8%, rgba(51,102,255,1) 85.5% )"},
{"id":"15821","gradient":"linear-gradient( 110.4deg,  rgba(255,196,254,1) 9.6%, rgba(251,229,123,1) 91% )"},
{"id":"208","gradient":"linear-gradient(to right, #0575e6, #021b79)"},
{"id":"1009","gradient":"linear-gradient( 106.5deg,  rgba(255,215,185,0.91) 23%, rgba(223,159,247,0.8) 93% )"},
{"id":"10238","gradient":"linear-gradient( 112.2deg,  rgba(86,223,223,1) 4.7%, rgba(2,28,186,1) 110.1% )"},
{"id":"27392","gradient":"radial-gradient( circle farthest-corner at 14.2% 24%,  rgba(239,61,78,1) 0%, rgba(239,61,78,0.81) 51.8%, rgba(239,61,78,0.63) 84.6% )"},
{"id":"9520","gradient":"linear-gradient( 109.6deg,  rgba(120,212,234,1) 11.2%, rgba(216,226,253,1) 72.1% )"},
{"id":"8865","gradient":"linear-gradient( 179deg,  rgba(110,144,242,1) 3.3%, rgba(245,193,193,1) 100% )"},
{"id":"950","gradient":"linear-gradient( 111.7deg,  rgba(251,198,6,1) 2.4%, rgba(224,82,95,1) 28.3%, rgba(194,78,154,1) 46.2%, rgba(32,173,190,1) 79.4%, rgba(22,158,95,1) 100.2% )"},
{"id":"27582","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(147,67,67,1) 0%, rgba(111,27,27,1) 90% )"},
{"id":"22265","gradient":"linear-gradient( 110.5deg,  rgba(71,114,148,1) 14.9%, rgba(149,245,211,1) 77.9% )"},
{"id":"22053","gradient":"linear-gradient( 76.9deg,  rgba(255,90,90,1) 27.2%, rgba(130,5,255,1) 79.9% )"},
{"id":"17975","gradient":"linear-gradient( 109.5deg,  rgba(229,233,177,1) 11.2%, rgba(223,205,187,1) 100.2% )"},
{"id":"34147","gradient":"radial-gradient( circle 400px at 6.8% 8.3%,  rgba(255,244,169,1) 0%, rgba(255,244,234,1) 100.2% )"},
{"id":"27263","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(247,87,0,1) 0%, rgba(249,0,0,1) 90.1% )"},
{"id":"14774","gradient":"linear-gradient( 110.9deg,  rgba(44,221,239,1) 1.1%, rgba(14,191,210,1) 37.6%, rgba(8,127,140,1) 99.2% )"},
{"id":"15805","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(253,203,50,1) 0%, rgba(244,56,98,1) 100.2% )"},
{"id":"13540","gradient":"linear-gradient( 180deg,  rgba(21,13,107,1) 1.1%, rgba(188,16,80,1) 130.5% )"},
{"id":"27371","gradient":"linear-gradient( 109.6deg,  rgba(245,56,56,1) 11.2%, rgba(234,192,117,1) 78% )"},
{"id":"27409","gradient":"linear-gradient( 180.4deg,  rgba(188,120,236,1) -2.2%, rgba(29,133,163,1) 83.5% )"},
{"id":"352","gradient":"linear-gradient( 178deg,  rgba(201,234,252,0.51) 14.9%, rgba(139,192,216,0.73) 80% )"},
{"id":"17282","gradient":"linear-gradient( 102.3deg,  rgba(147,39,143,1) 5.9%, rgba(234,172,232,1) 64%, rgba(246,219,245,1) 89% )"},
{"id":"22419","gradient":"radial-gradient( circle 602px at 2.1% 5.1%,  rgba(233,0,120,1) 0%, rgba(0,0,0,1) 90.1% )"},
{"id":"15776","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(130,205,221,1) 0%, rgba(255,247,153,1) 90% )"},
{"id":"5375","gradient":"linear-gradient( 110.7deg,  rgba(9,154,151,1) 6.3%, rgba(21,205,168,1) 90.6% )"},
{"id":"7180","gradient":"linear-gradient( 89.4deg,  rgba(74,77,103,1) -4.3%, rgba(119,125,165,1) 102.1% )"},
{"id":"1046","gradient":"linear-gradient( 111.5deg,  rgba(187,232,255,1) 11.1%, rgba(4,78,116,1) 107.5% )"},
{"id":"15660","gradient":"linear-gradient( 109.8deg,  rgba(62,5,116,1) -5.2%, rgba(41,14,151,1) -5.2%, rgba(216,68,148,1) 103.3% )"},
{"id":"6959","gradient":"radial-gradient( circle farthest-corner at 18.7% 37.8%,  rgba(250,250,250,1) 0%, rgba(225,234,238,1) 90% )"},
{"id":"317","gradient":"linear-gradient( 90.5deg,  rgba(35,210,255,1) 0.3%, rgba(74,104,247,1) 18.2%, rgba(133,80,255,1) 36.4%, rgba(198,59,243,1) 52.5%, rgba(250,84,118,1) 68.8%, rgba(255,223,67,1) 99.9% )"},
{"id":"22083","gradient":"linear-gradient( 109.6deg,  rgba(255,115,68,1) 11.2%, rgba(244,255,32,1) 100.6% )"},
{"id":"14668","gradient":"linear-gradient( 90.3deg,  rgba(255,255,255,1) 3%, rgba(235,158,98,1) 34.6%, rgba(177,10,10,1) 63.7%, rgba(0,0,0,1) 102% )"},
{"id":"13674","gradient":"radial-gradient( circle farthest-corner at 50.3% 44.5%,  rgba(116,147,179,1) 0%, rgba(62,83,104,1) 100.2% )"},
{"id":"387","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(164,38,199,1) 0.1%, rgba(65,220,255,1) 90.1% )"},
{"id":"27325","gradient":"linear-gradient( 73.1deg,  rgba(34,126,34,1) 8%, rgba(99,162,17,1) 86.9% )"},
{"id":"17204","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(147,230,241,1) 0%, rgba(145,192,241,1) 45.5% )"},
{"id":"9270","gradient":"linear-gradient( 106.8deg,  rgba(117,255,220,1) 6%, rgba(163,216,255,1) 47.6%, rgba(248,215,251,1) 87.8% )"},
{"id":"369","gradient":"linear-gradient( 109.6deg,  rgba(245,239,249,1) 30.1%, rgba(207,211,236,1) 100.2% )"},
{"id":"5033","gradient":"linear-gradient( 243.4deg,  rgba(0,215,206,1) 13%, rgba(0,132,255,1) 98% )"},
{"id":"10164","gradient":"linear-gradient( 110.1deg,  rgba(241,115,30,1) 18.9%, rgba(231,29,54,1) 90.7% )"},
{"id":"15290","gradient":"radial-gradient( circle 654px at 0.6% 48%,  rgba(12,170,255,1) 0%, rgba(151,255,129,1) 86.3% )"},
{"id":"26820","gradient":"linear-gradient( 126.3deg,  rgba(30,2,83,1) 32.2%, rgba(198,55,160,0.46) 109.2% )"},
{"id":"9747","gradient":"linear-gradient( 89.5deg,  rgba(104,208,232,1) 1.5%, rgba(231,144,245,1) 100.5% )"},
{"id":"1059","gradient":"linear-gradient( 91.7deg,  rgba(50,25,79,1) -4.3%, rgba(122,101,149,1) 101.8% )"},
{"id":"15396","gradient":"radial-gradient( circle farthest-corner at 10% 20%,  rgba(111,111,219,1) 0%, rgba(182,109,246,1) 72.4% )"},
{"id":"13607","gradient":"linear-gradient( 87.9deg,  rgba(140,253,242,1) 9.7%, rgba(81,162,228,1) 45.5%, rgba(154,16,154,1) 92.5% )"},
{"id":"12334","gradient":"radial-gradient( circle 1173px at -3.5% -4.8%,  rgba(255,140,16,1) 8.4%, rgba(255,26,26,1) 20.6%, rgba(0,64,249,1) 46.7%, rgba(131,201,8,1) 69.6%, rgba(255,230,28,1) 84.7% )"},
{"id":"16181","gradient":"linear-gradient( 90.1deg,  rgba(158,252,203,1) 16.8%, rgba(63,100,255,1) 105.2% )"},
{"id":"27221","gradient":"linear-gradient( 106.9deg,  rgba(148,14,60,1) 60.9%, rgba(3,22,27,1) 122.3% )"},
{"id":"9067","gradient":"linear-gradient( 72.3deg,  rgba(29,7,64,1) 8.5%, rgba(253,105,139,1) 92.2% )"}]



function getRandomBackground() {

    let randomIndex=Math.floor(Math.random() * bgList.length);
    return bgList[randomIndex]

}

export {getRandomBackground}