import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


import axiosInstance from './axios-config';  // 导入axios配置


Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)


import i18n from './i18n';


const messages = {
  en: {
    welcome: 'Welcome!',
    // Add more key-value pairs for English
  },
  zh: {
    welcome: '欢迎！',
    // Add more key-value pairs for Chinese
  },
  // Add more languages as needed
};

//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
Vue.config.productionTip = false




const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
//解决报错。【https://blog.csdn.net/luer_LJS/article/details/108362563】


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.prototype.$axios = axiosInstance;

new Vue({
  render: h => h(App),
  router,
  i18n,

}).$mount('#app')


