import VueRouter from 'vue-router'

import HelloWorld from "../components/HelloWorld.vue";
import Mde from "../components/Mde.vue";
import Detail from "../components/Detail.vue";
import Blog from "../components/Blog.vue";
import Index from "../components/Index.vue";

export default new VueRouter({
	routes: [
		{
			path: '/',
			name: 'index0',
			component: Index,
		},
		{
			path: '/index',
			name: 'index1',
			component: Index,
		},
		{
			path: '/blog',
			name: 'blog',
			component: Blog,
			children: [
				{
					path: '/',
					name: 'edit',
					component: HelloWorld,
					props: true,
				},
				{
					path: 'edit/:id',
					name: 'edit',
					component: Mde,
					props: true,

				},
				{
					path: 'write',
					name: 'write',
					component: Mde,
				},

				{
					path: 'detail/:id',
					name: 'Detail',
					component: Detail,
					props: true,
				},
			]
		},




	]
})
