// i18n.js

import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    welcome: 'Welcome!',
    
    // Add more key-value pairs for English
  },
  zh: {
    welcome: '欢迎！',
    // Add more key-value pairs for Chinese
  },
  // Add more languages as needed
};

const i18n = new VueI18n({
  locale: 'en', // set the initial locale
  fallbackLocale: 'en', // set the fallback locale
  messages,
});

export default i18n;
