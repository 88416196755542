<template>

<!-- 1000 720  -->

  <div id="app">
    <Headerr></Headerr>
      <router-view></router-view>
    <!-- <HelloWorld></HelloWorld> -->
   <Foot></Foot> 
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Mde from './components/Mde.vue'
import Foot from './components/Foot.vue'
import Headerr from './components/Header.vue'

export default {
  name: 'App',
  components: {
    HelloWorld,
    Mde,
    Foot,
    Headerr,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  
}

*{

  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

body {
  margin: 0 auto;
  min-width: 320px;
  min-height: 100vh;
  /* background-color: rgb(25, 25, 28); */
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
}

.md-img{
  max-width: 100%;
}
</style>
