<template>
  <div>
    <div v-for="article in articles">

      <div class="article">

        <router-link class="qqq" :to="'detail/' + (article.id)" append>
          
          <div class="mdsInfos">

            <div class="mdsCover">
              <img :src="(article.urlImgcol)" alt="" style="width: 100%;">
            </div>

            <div class="mdsTexts">
              <span class="title">
                {{ article.title }}
              </span>

              <br>
              <span class="excerpt">
                {{ article.excerpt }}
              </span>

              <br>
              <span>
                {{ article.createTime }}
              </span>
            </div>

          </div>
        </router-link>

      </div>


    </div>
  </div>
</template>


<style lang="less" scoped>
.article {

  margin: 0 10px 10px;
  border: 1px solid black;
  padding: 8px;

  >.qqq {

    text-decoration: none;

    .mdsInfos {
      @coverSize: 10vw;
      width: 100%;
      color: black;
      display: flex;
      align-items: center;

      @sfasfa:50%;
      >.mdsCover {
        width: 150px;
        min-width: 150px; //禁止挤兑
        box-sizing: border-box;
        height: 100%;
        margin-right: 20px;
        >img{
          width: 100%;
        }
      }


      >.mdsTexts {

        >.title {
          font-weight: 800;
          font-size: 30px;
        }

        >.excerpt {
          font-size: 18px;
        }
      }

    }
  }

}
</style>


<script>

export default {

  data() {
    return {
      articles: [],
    };
  },
  methods: {
    initData() {
      this.$axios.get("/api/article/all")
        .then(res => {
          // console.log(res);
          this.articles = res.data;

          // const imgIds = this.articles.map(article => article.coverId);
        })


    },


    goDetail(mdId) {
      console.log(mdId)
    }
  },
  mounted() {
    this.initData()
  },
};
</script>

